import axios from 'axios';
import { Notify } from 'notiflix';
import { downloadLogs } from './logger';
import { logToLocalStorage } from '../services/logger'; // Импорт логирования

axios.defaults.baseURL = `http${
  process.env.REACT_APP_SECURE === 'true' ? 's' : ''
}://${process.env.REACT_APP_BACKEND_URL}/api`;

export const token = {
  set(token) {
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  },
  unset() {
    axios.defaults.headers.common.Authorization = '';
  },
};

export const setLoginTime = () => {
  const LoginTime = localStorage.getItem('LoginTime');
  const currentTime = new Date();

  if (LoginTime) {
    const previousLoginTime = new Date(LoginTime);
    const hoursDifference = Math.abs(currentTime - previousLoginTime) / 36e5;

    // Обновляем время входа, если прошло более 8 часов
    if (hoursDifference >= 8) {
      localStorage.setItem('LoginTime', currentTime.toISOString());
    }
  } else {
    // Если нет предыдущей записи, устанавливаем текущее время как время входа
    localStorage.setItem('LoginTime', currentTime.toISOString());
  }
};

export const loginFoo = async credentials => {
  const post = await axios.post('token/login', credentials);
  setLoginTime();
  logToLocalStorage(
    `Request : loginFoo, Status : ${post.status}, StatusText : ${post.statusText}, responseURL : ${post.request.responseURL}`
  );
  return post.data;
};

//   export const logoutFoo = async () => {
//     const { data } = await axios.post('/api/logout');  ПОКА НЕТУ

//     return data;
//   };

export const getCurrentUser = async credentials => {
  const post = await axios.get('user', credentials);
  logToLocalStorage(
    `Request : getCurrentUser, Status : ${post.status}, StatusText : ${post.statusText}, responseURL : ${post.request.responseURL}`
  );
  return post.data;
};

export const getCurrentUserToken = async token => {
  try {
    const post = await axios.get('user', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    logToLocalStorage(
      `Request : getCurrentUserToken, Status : ${post.status}, StatusText : ${post.statusText}, responseURL : ${post.request.responseURL}`
    );
    return post.data;
  } catch (error) {
    console.error(
      'Error fetching data:',
      error.response?.data || error.message
    );
    logToLocalStorage(
      `Request : getCurrentUserToken, Error : ${error.message}`
    );
    throw error;
  }
};

export const postUserPassword = async (token, profile) => {
  try {
    const post = await axios.post('user/change-password', profile, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    logToLocalStorage(
      `Request : postUserPassword, Status : ${post.status}, StatusText : ${post.statusText}, responseURL : ${post.request.responseURL}`
    );
    return post.data;
  } catch (error) {
    console.error(
      'Error postUserPassword:',
      error.response?.data || error.message
    );
    logToLocalStorage(`Request : postUserPassword, Error : ${error.message}`);
    throw error;
  }
};

export const getUserProfile = async token => {
  try {
    const post = await axios.get('user/profile', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    logToLocalStorage(
      `Request : getUserProfile, Status : ${post.status}, StatusText : ${post.statusText}, responseURL : ${post.request.responseURL}`
    );
    return post.data;
  } catch (error) {
    console.error(
      'Error fetching data:',
      error.response?.data || error.message
    );
    logToLocalStorage(`Request : getUserProfile, Error : ${error.message}`);
    throw error;
  }
};

export const putUserProfile = async (token, userData) => {
  try {
    const post = await axios.put('user/profile', userData, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    logToLocalStorage(
      `Request : putUserProfile, Status : ${post.status}, StatusText : ${post.statusText}, responseURL : ${post.request.responseURL}`
    );
    return post.data;
  } catch (error) {
    console.error(
      'Error putUserProfile:',
      error.response?.data || error.message
    );
    logToLocalStorage(`Request : putUserProfile, Error : ${error.message}`);
    throw error;
  }
};

export const postUserPaymentMethod = async (token, paymentMethod) => {
  try {
    const post = await axios.post('user/change-payment-method', paymentMethod, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    console.log('Response from server:', post.data);
    logToLocalStorage(
      `Request : postUserPaymentMethod, Status : ${post.status}, StatusText : ${post.statusText}, responseURL : ${post.request.responseURL}`
    );
    return post.data;
  } catch (error) {
    console.error(
      'Error postUserPaymentMethod:',
      error.response?.data || error.message
    );
    logToLocalStorage(
      `Request : postUserPaymentMethod, Error : ${error.message}`
    );
    throw error;
  }
};

export const getUsers = async (token, groups, tid) => {
  try {
    const post = await axios.get(
      `schedule/?schedule_group=${groups}&tid=${tid}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    logToLocalStorage(
      `Request : getUsers, Status : ${post.status}, StatusText : ${post.statusText}, responseURL : ${post.request.responseURL}`
    );
    return post.data;
  } catch (error) {
    console.error(
      'Error fetching data:',
      error.response?.data || error.message
    );
    logToLocalStorage(`Request : getUsers, Error : ${error.message}`);
    throw error;
  }
};

export const getScheduleCreation = async token => {
  try {
    const post = await axios.get('schedule/table/creation', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    logToLocalStorage(
      `Request : getScheduleCreation, Status : ${post.status}, StatusText : ${post.statusText}, responseURL : ${post.request.responseURL}`
    );
    return post.data;
  } catch (error) {
    console.error(
      'Error fetching data:',
      error.response?.data || error.message
    );
    console.error('Full error:', error);
    logToLocalStorage(
      `Request : getScheduleCreation, Error : ${error.message}`
    );
    throw error;
  }
};

export const postScheduleCreation = async (token, scheduleData) => {
  try {
    const post = await axios.post('schedule/table/creation', scheduleData, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    logToLocalStorage(
      `Request : postScheduleCreation, Status : ${post.status}, StatusText : ${post.statusText}, responseURL : ${post.request.responseURL}`
    );
    return post.data;
  } catch (error) {
    console.error(
      'Error creating schedule:',
      error.response?.data || error.message
    );
    logToLocalStorage(
      `Request : postScheduleCreation, Error : ${error.message}`
    );
    throw error;
  }
};

export const getSchedulePersonalTable = async (token, tid) => {
  try {
    const post = await axios.get(`schedule/personal-table/?tid=${tid}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    logToLocalStorage(
      `Request : getSchedulePersonalTable, Status : ${post.status}, StatusText : ${post.statusText}, responseURL : ${post.request.responseURL}`
    );
    return post.data;
  } catch (error) {
    console.error(
      'Error fetching data:',
      error.response?.data || error.message
    );
    console.error('Full error:', error);
    logToLocalStorage(
      `Request : getSchedulePersonalTable, Error : ${error.message}`
    );
    throw error;
  }
};

export const getScheduleWorker = async (token, action) => {
  try {
    const post = await axios.get(`schedule/worker?action=${action}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    logToLocalStorage(
      `Request : getScheduleWorker, Status : ${post.status}, StatusText : ${post.statusText}, responseURL : ${post.request.responseURL}`
    );
    return post.data;
  } catch (error) {
    console.error(
      'Error fetching data:',
      error.response?.data || error.message
    );
    console.error('Full error:', error);
    logToLocalStorage(`Request : getScheduleWorker, Error : ${error.message}`);
    throw error;
  }
};

export const putScheduleShift = async (token, scheduleData) => {
  try {
    const post = await axios.put('schedule/table/shift', scheduleData, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    logToLocalStorage(
      `Request : putScheduleShift, Status : ${post.status}, StatusText : ${post.statusText}, responseURL : ${post.request.responseURL}`
    );
    return post.data;
  } catch (error) {
    console.error(
      'Error putScheduleCreation:',
      error.response?.data || error.message
    );
    logToLocalStorage(`Request : putScheduleShift, Error : ${error.message}`);
    throw error;
  }
};

export const getAntifraudShift = async token => {
  try {
    const post = await axios.get('antifraud/shift', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    logToLocalStorage(
      `Request : getAntifraudShift, Status : ${post.status}, StatusText : ${post.statusText}, responseURL : ${post.request.responseURL}`
    );
    return post.data;
  } catch (error) {
    console.error(
      'Error fetching data:',
      error.response?.data || error.message
    );
    logToLocalStorage(`Request : getAntifraudShift, Error : ${error.message}`);
    throw error;
  }
};

export const postAntifraudShift = async (shiftData, token) => {
  try {
    const post = await axios.post('antifraud/shift', shiftData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    logToLocalStorage(
      `Request : postAntifraudShift, Status : ${post.status}, StatusText : ${post.statusText}, responseURL : ${post.request.responseURL}`
    );
    return post.data;
  } catch (error) {
    console.error(
      'Error postAntifraudShift:',
      error.response?.data || error.message
    );
    logToLocalStorage(`Request : postAntifraudShift, Error : ${error.message}`);
    throw error;
  }
};

export const getAntifraudRequest = async token => {
  try {
    const post = await axios.get(`antifraud/request`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    logToLocalStorage(
      `Request : getAntifraudRequest, Status : ${post.status}, StatusText : ${post.statusText}, responseURL : ${post.request.responseURL}`
    );

    if (!post.data) {
      await new Promise(resolve => setTimeout(resolve, 10000)); 
    }

    return post.data;
  } catch (error) {
    const errorCode = error?.response?.status || 'Неизвестная ошибка';

    let errorMessage;

    switch (errorCode) {
      case 401:
        errorMessage = 'Ошибка 401 – Перезайдите в аккаунт';
        break;
      case 403:
        errorMessage =
          'Ошибка 403 – Обновите сайт и перепроверьте, начата ли смена';
        break;
      case 404:
        errorMessage = 'Ошибка 404 – Такая заявка потерялась';
        break;
      case 429:
        errorMessage =
          'Ошибка 429 – Слишком много запросов. Пожалуйста, подождите и попробуйте позже';
        break;
      default:
        errorMessage = `Произошла ошибка: ${errorCode}. Сообщите в тех. поддержку`;
    }

    Notify.failure(errorMessage);
    logToLocalStorage(
      `Request : getAntifraudRequest, Error : ${error.message}`
    );
  }
};

export const postAntifraudRequest = async (shiftData, token) => {
  try {
    const post = await axios.post('antifraud/request', shiftData, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
    });
    logToLocalStorage(
      `Request : postAntifraudRequest, Status : ${post.status}, StatusText : ${post.statusText}, responseURL : ${post.request.responseURL}`
    );
    return post.data;
  } catch (error) {
    console.error(
      'Error postAntifraudRequest:',
      error.response?.data || error.message
    );
    logToLocalStorage(
      `Request : postAntifraudRequest, Error : ${error.message}`
    );
    throw error;
  }
};

export const getAntifraudRequestEscalade = async (token, initialState = {}) => {
  try {
    const post = await axios.get('antifraud/request?type=escalades', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const d = post.data;
    logToLocalStorage(
      `Request : getAntifraudRequestEscalade, Status : ${post.status}, StatusText : ${post.statusText}, responseURL : ${post.request.responseURL}`
    );
    return { ...initialState, d };
  } catch (error) {
    console.error(
      'Error getAntifraudRequest:',
      error.response?.data || error.message
    );
    logToLocalStorage(
      `Request : getAntifraudRequestEscalade, Error : ${error.message}`
    );
    throw error;
  }
};

export const getCashback = async (token, page) => {
  try {
    const post = await axios.get(`cashback?page=${page}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    logToLocalStorage(
      `Request : getCashback, Status : ${post.status}, StatusText : ${post.statusText}, responseURL : ${post.request.responseURL}`
    );
    const d = post.data;
    return { d };
  } catch (error) {
    console.error(
      'Error getAntifraudRequest:',
      error.response?.data || error.message
    );
    logToLocalStorage(`Request : getCashback, Error : ${error.message}`);
    throw error;
  }
};

export const getSearchCashback = async (token, page, userId) => {
  try {
    const post = await axios.get(`cashback?page=${page}&query=${userId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const d = post.data;
    logToLocalStorage(
      `Request : getSearchCashback, Status : ${post.status}, StatusText : ${post.statusText}, responseURL : ${post.request.responseURL}`
    );
    return { d };
  } catch (error) {
    console.error(
      'Error getAntifraudRequest:',
      error.response?.data || error.message
    );
    logToLocalStorage(`Request : getSearchCashback, Error : ${error.message}`);
    throw error;
  }
};

export const postScheduleWorker = async (token, shiftData) => {
  try {
    const post = await axios.post('schedule/worker', shiftData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    logToLocalStorage(
      `Request : postScheduleWorker, Status : ${post.status}, StatusText : ${post.statusText}, responseURL : ${post.request.responseURL}`
    );
    return post.data;
  } catch (error) {
    console.error(
      'Error postAntifraudRequest:',
      error.response?.data || error.message
    );
    logToLocalStorage(`Request : postScheduleWorker, Error : ${error.message}`);
    throw error;
  }
};

export let socket;
export let table;

export const webOpen = token => {
  return (socket = new WebSocket(
    `ws${process.env.REACT_APP_SECURE === 'true' ? 's' : ''}://${
      process.env.REACT_APP_BACKEND_URL
    }/ws/?token=${token}`
  ));
};

export const webUpdate = setDataCallback => {
  socket.onmessage = event => {
    const data = JSON.parse(event.data);
    setDataCallback(data);
  };
};

export const postBugReport = async (shiftData, token) => {
  try {
    const post = await axios.post('bug-report', shiftData, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
    });
    logToLocalStorage(
      `Request : postBugReport, Status : ${post.status}, StatusText : ${post.statusText}, responseURL : ${post.request.responseURL}`
    );
    return post.data;
  } catch (error) {
    console.error(
      'Error postAntifraudRequest:',
      error.response?.data || error.message
    );
    logToLocalStorage(`Request : postBugReport, Error : ${error.message}`);
    throw error;
  }
};

export const postChangeProfilePhoto = async (formData, token) => {
  try {
    const post = await axios.post('user/change-profile-photo', formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
    });
    logToLocalStorage(
      `Request : postChangeProfilePhoto, Status : ${post.status}, StatusText : ${post.statusText}, responseURL : ${post.request.responseURL}`
    );
    return post.data;
  } catch (error) {
    console.error(
      'Error postChangeProfilePhoto:',
      error.response?.data || error.message
    );
    logToLocalStorage(
      `Request : postChangeProfilePhoto, Error : ${error.message}`
    );
    throw error;
  }
};

export const postNeededData = async (token, neededData) => {
  try {
    const post = await axios.post('schedule/table/creation', neededData, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
    });
    logToLocalStorage(
      `Request : postNeededData, Status : ${post.status}, StatusText : ${post.statusText}, responseURL : ${post.request.responseURL}`
    );
    return post.data;
  } catch (error) {
    console.error(
      'Error postNeededData:',
      error.response?.data || error.message
    );
    logToLocalStorage(`Request : postNeededData, Error : ${error.message}`);
    throw error;
  }
};

export const getHoldRequests = async token => {
  try {
    const post = await axios.get('antifraud/hold-requests', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    logToLocalStorage(
      `Request : getHoldRequests, Status : ${post.status}, StatusText : ${post.statusText}, responseURL : ${post.request.responseURL}`
    );
    return post.data;
  } catch (error) {
    console.error(error.response?.data || error.message);
    logToLocalStorage(`Request : getHoldRequests, Error : ${error.message}`);
    throw error;
  }
};

export const getDataBaseNode = async (token, id) => {
  try {
    const url =
      id === 'null' ? `knowledgebase/node` : `knowledgebase/node?id=${id}`;
    const post = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    logToLocalStorage(
      `Request : getDataBaseNode, Status : ${post.status}, StatusText : ${post.statusText}, responseURL : ${post.request.responseURL}`
    );
    return post.data;
  } catch (error) {
    console.error(error.response?.data || error.message);
    logToLocalStorage(`Request : getDataBaseNode, Error : ${error.message}`);
    throw error;
  }
};

export const postDataBaseNode = async (token, neededData) => {
  console.log(neededData);
  try {
    const post = await axios.post('knowledgebase/node', neededData, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
    });
    logToLocalStorage(
      `Request : postDataBaseNode, Status : ${post.status}, StatusText : ${post.statusText}, responseURL : ${post.request.responseURL}`
    );
    return post.data;
  } catch (error) {
    console.error(error.response?.data || error.message);
    logToLocalStorage(`Request : postDataBaseNode, Error : ${error.message}`);
    throw error;
  }
};

export const putDataBaseNode = async (token, neededData) => {
  console.log(neededData);
  try {
    const post = await axios.put('knowledgebase/node', neededData, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
    });
    logToLocalStorage(
      `Request : putDataBaseNode, Status : ${post.status}, StatusText : ${post.statusText}, responseURL : ${post.request.responseURL}`
    );
    return post.data;
  } catch (error) {
    console.error(error.response?.data || error.message);
    logToLocalStorage(`Request : putDataBaseNode, Error : ${error.message}`);
    throw error;
  }
};

export const deleteDataBaseNode = async (token, id) => {
  try {
    const url =
      id === 'null' ? `knowledgebase/node` : `knowledgebase/node?id=${id}`;
    const post = await axios.delete(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    logToLocalStorage(
      `Request : deleteDataBaseNode, Status : ${post.status}, StatusText : ${post.statusText}, responseURL : ${post.request.responseURL}`
    );
    return post.data;
  } catch (error) {
    console.error(error.response?.data || error.message);
    logToLocalStorage(`Request : deleteDataBaseNode, Error : ${error.message}`);
    throw error;
  }
};

export const getDatBaseArticle = async (token, id) => {
  try {
    const post = await axios.get(`knowledgebase/article?id=${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    logToLocalStorage(
      `Request : getDatBaseArticle, Status : ${post.status}, StatusText : ${post.statusText}, responseURL : ${post.request.responseURL}`
    );
    return post.data;
  } catch (error) {
    console.error(error.response?.data || error.message);
    logToLocalStorage(`Request : getDatBaseArticle, Error : ${error.message}`);
    throw error;
  }
};

export const postDatBaseArticle = async (token, neededData) => {
  try {
    const post = await axios.post('knowledgebase/article', neededData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    logToLocalStorage(
      `Request : postDatBaseArticle, Status : ${post.status}, StatusText : ${post.statusText}, responseURL : ${post.request.responseURL}`
    );
    return post.data;
  } catch (error) {
    console.error(error.response?.data || error.message);
    logToLocalStorage(`Request : postDatBaseArticle, Error : ${error.message}`);
    throw error;
  }
};

export const putDatBaseArticle = async (token, neededData) => {
  try {
    const post = await axios.put('knowledgebase/article', neededData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    logToLocalStorage(
      `Request : putDatBaseArticle, Status : ${post.status}, StatusText : ${post.statusText}, responseURL : ${post.request.responseURL}`
    );
    return post.data;
  } catch (error) {
    console.error(error.response?.data || error.message);
    logToLocalStorage(`Request : putDatBaseArticle, Error : ${error.message}`);
    throw error;
  }
};

export const deleteDatBaseArticle = async (token, id) => {
  try {
    const url =
      id === 'null'
        ? `knowledgebase/article`
        : `knowledgebase/article?id=${id}`;
    const post = await axios.delete(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    logToLocalStorage(
      `Request : deleteDatBaseArticle, Status : ${post.status}, StatusText : ${post.statusText}, responseURL : ${post.request.responseURL}`
    );
    return post.data;
  } catch (error) {
    console.error(error.response?.data || error.message);
    logToLocalStorage(
      `Request : deleteDatBaseArticle, Error : ${error.message}`
    );
    throw error;
  }
};

export const postDatBaseArticlePhoto = async (token, formData) => {
  try {
    const post = await axios.post('knowledgebase/article-image', formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
    });
    logToLocalStorage(
      `Request : postDatBaseArticlePhoto, Status : ${post.status}, StatusText : ${post.statusText}, responseURL : ${post.request.responseURL}`
    );
    return post.data;
  } catch (error) {
    console.error(error.response?.data || error.message);
    logToLocalStorage(
      `Request : postDatBaseArticlePhoto, Error : ${error.message}`
    );
    throw error;
  }
};

export const deleteDatBaseArticlePhoto = async (
  token,
  article_id,
  image_id
) => {
  try {
    const post = await axios.delete(
      `knowledgebase/article-image?article_id=${article_id}&image_id=${image_id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    logToLocalStorage(
      `Request : deleteDatBaseArticlePhoto, Status : ${post.status}, StatusText : ${post.statusText}, responseURL : ${post.request.responseURL}`
    );
    return post.data;
  } catch (error) {
    console.error(error.response?.data || error.message);
    logToLocalStorage(
      `Request : deleteDatBaseArticlePhoto, Error : ${error.message}`
    );
    throw error;
  }
};

export const getFraudPersonalStatistics = async (token, startDate, dayType) => {
  try {
    const post = await axios.get(
      `antifraud/stat/self?date=${startDate}&shift_type=${dayType}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    logToLocalStorage(
      `Request : getFraudPersonalStatistics, Status : ${post.status}, StatusText : ${post.statusText}, responseURL : ${post.request.responseURL}`
    );
    return post.data;
  } catch (error) {
    console.error(error.response?.data || error.message);
    logToLocalStorage(
      `Request : getFraudPersonalStatistics, Error : ${error.message}`
    );
    throw error;
  }
};

export const getFraudPersonalStatisticsPk = async (
  token,
  agent,
  startDate,
  dayType
) => {
  try {
    const post = await axios.get(
      `antifraud/stat/self?date=${startDate}&pk=${agent}&shift_type=${dayType}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    logToLocalStorage(
      `Request : getFraudPersonalStatisticsPk, Status : ${post.status}, StatusText : ${post.statusText}, responseURL : ${post.request.responseURL}`
    );
    return post.data;
  } catch (error) {
    console.error(error.response?.data || error.message);
    logToLocalStorage(
      `Request : getFraudPersonalStatisticsPk, Error : ${error.message}`
    );
    throw error;
  }
};

export const getFraudTlStatistics = async (token, startDate, dayType) => {
  try {
    const post = await axios.get(
      `antifraud/stat/department?date=${startDate}&shift_type=${dayType}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    logToLocalStorage(
      `Request : getFraudTlStatistics, Status : ${post.status}, StatusText : ${post.statusText}, responseURL : ${post.request.responseURL}`
    );
    return post.data;
  } catch (error) {
    console.error(error.response?.data || error.message);
    logToLocalStorage(
      `Request : getFraudTlStatistics, Error : ${error.message}`
    );
    throw error;
  }
};

export const getFraudTeamStatistics = async (
  token,
  project,
  startDate,
  dayType
) => {
  try {
    const post = await axios.get(
      `antifraud/stat/team?date=${startDate}&project=${project}&shift_type=${dayType}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    logToLocalStorage(
      `Request : getFraudTeamStatistics, Status : ${post.status}, StatusText : ${post.statusText}, responseURL : ${post.request.responseURL}`
    );
    return post.data;
  } catch (error) {
    console.error(error.response?.data || error.message);
    logToLocalStorage(
      `Request : getFraudTeamStatistics, Error : ${error.message}`
    );
    throw error;
  }
};

export const getArchive = async (token, id, page) => {
  try {
    const post = await axios.get(
      `antifraud/archived-requests?id=${id}&page=${page}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const d = post.data;
    logToLocalStorage(
      `Request : getArchive, Status : ${post.status}, StatusText : ${post.statusText}, responseURL : ${post.request.responseURL}`
    );
    return { d };
  } catch (error) {
    console.error(
      'Error getAntifraudRequest:',
      error.response?.data || error.message
    );
    logToLocalStorage(`Request : getArchive, Error : ${error.message}`);
    throw error;
  }
};

export const getVipEmailsArchive = async (token, page) => {
  try {
    const post = await axios.get(
      `vipemails?page=${page}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const d = post.data;
    logToLocalStorage(
      `Request : getVipEmailsArchive, Status : ${post.status}, StatusText : ${post.statusText}, responseURL : ${post.request.responseURL}`
    );
    return { d };
  } catch (error) {
    console.error(
      'Error getVipEmailsArchive:',
      error.response?.data || error.message
    );
    logToLocalStorage(`Request : getVipEmailsArchive, Error : ${error.message}`);
    throw error;
  }
};

export const getSearchEmail = async (token, email) => {
  try {
    const post = await axios.get(`vipemails?query=${email}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const d = post.data;
    logToLocalStorage(
      `Request : getSearchEmail, Status : ${post.status}, StatusText : ${post.statusText}, responseURL : ${post.request.responseURL}`
    );
    return { d };
  } catch (error) {
    console.error(
      'Error getSearchEmail:',
      error.response?.data || error.message
    );
    logToLocalStorage(`Request : getSearchEmail, Error : ${error.message}`);
    throw error;
  }
};

export const getLiveChatAgent = async (
  token,
  page,
  selectedColor,
  selectedStatus,
  searchId,
  isAfterDate,
  searchUser,
  isBeforeDate,
  project
) => {
  try {
    const params = {
      ...(page && { page: page }),
      ...(selectedColor && { chat_color: selectedColor }),
      ...(selectedStatus && { user_type: selectedStatus }),
      ...(searchId && { chat_id: searchId }),
      ...(isAfterDate && { created_at_after: isAfterDate }),
      ...(searchUser && { username: searchUser }),
      ...(isBeforeDate && { created_at_before: isBeforeDate }),
      ...(project && { project: project }),
    };

    const post = await axios.get('chatqa/reviewed-chats', {
      params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    // logToLocalStorage(
    //   `Request : getUserProfile, Status : ${post.status}, StatusText : ${post.statusText}, responseURL : ${post.request.responseURL}`
    // );
    return post.data;
  } catch (error) {
    console.error(
      'Error fetching data:',
      error.response?.data || error.message
    );
    logToLocalStorage(`Request : getUserProfile, Error : ${error.message}`);
    throw error;
  }
};

export const getLiveChatAgentId = async (token, id) => {
  try {
    const post = await axios.get(`chatqa/reviewed-chats/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    // logToLocalStorage(
    //   `Request : getUserProfile, Status : ${post.status}, StatusText : ${post.statusText}, responseURL : ${post.request.responseURL}`
    // );
    return post.data;
  } catch (error) {
    console.error(
      'Error fetching data:',
      error.response?.data || error.message
    );
    logToLocalStorage(`Request : getUserProfile, Error : ${error.message}`);
    throw error;
  }
};

export const getPaymentBotStat = async (token, dateFrom, dateTo) => {
  try {
    const post = await axios.get(`payment_bot/detail-statistic?from_date=${dateFrom}&to_date=${dateTo}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    // logToLocalStorage(
    //   `Request : getUserProfile, Status : ${post.status}, StatusText : ${post.statusText}, responseURL : ${post.request.responseURL}`
    // );
    return post.data;
  } catch (error) {
    console.error(
      'Error fetching data:',
      error.response?.data || error.message
    );
    logToLocalStorage(`Request : getUserProfile, Error : ${error.message}`);
    throw error;
  }
};
