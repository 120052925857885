import styled from 'styled-components';
import { PiTelegramLogo } from 'react-icons/pi';
import { IoShield } from 'react-icons/io5';

export const QaMessageAreaConteiner = styled.div`
  margin-left: 381px;
  position: fixed;
  width: 1181px;
  height: 100%;

  border-right: 1px solid #dfdaeb;

  @media (min-width: 1500px) {
    margin-left: 380px;
    width: 791px;
  }
  @media (min-width: 1600px) {
    margin-left: 380px;
    width: 880px;
  }
  @media (min-width: 1700px) {
    margin-left: 380px;
    width: 991px;
  }
  @media (min-width: 1800px) {
    margin-left: 380px;
    width: 1090px;
  }
  @media (min-width: 1900px) {
    margin-left: 381px;
    width: 1181px;
  }
`;

export const MessageConteiner = styled.div`
  width: 1182px;
  height: 600px;
`;

export const TextAreaQa = styled.textarea`
  width: 742px;
  height: 45px;
  resize: none;
  position: absolute;
  top: 650px;
  left: 200px;
  background: #ffffff;
  box-shadow: 0px 2px 22.3px rgba(0, 0, 0, 0.16);
  border-radius: 15px;
  padding: 8px;
  padding-bottom: 65px;

  @media (min-width: 1500px) {
    left: 17px;
  }
  @media (min-width: 1600px) {
    left: 60px;
  }
  @media (min-width: 1700px) {
    left: 95px;
  }
  @media (min-width: 1800px) {
    left: 160px;
  }
  @media (min-width: 1900px) {
    left: 200px;
  }
`;

export const TextAreaToolsConteiner = styled.div`
  position: absolute;
  top: 730px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 700px;

  @media (min-width: 1500px) {
    left: 45px;
  }
  @media (min-width: 1600px) {
    left: 85px;
  }
  @media (min-width: 1700px) {
    left: 118px;
  }
  @media (min-width: 1800px) {
    left: 180px;
  }
  @media (min-width: 1900px) {
    left: 230px;
  }
`;

export const TagBlock = styled.div``;

export const SendButton = styled.button`
  width: 49px;
  height: 20px;

  box-shadow: 0px 2px 22.3px rgba(0, 0, 0, 0.16);
  background: #0a1019;
  border-radius: 5px;

  font-family: 'Geologica';
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;

  color: #ffffff;

  cursor: pointer;
  display: flex;
  align-items: center;
`;

export const TagButton = styled.button`
  width: 90px;
  height: 20px;
  background: transparent;
  border: 0;

  font-family: 'Geologica';
  font-style: normal;
  font-weight: 300;
  font-size: 11px;
  line-height: 14px;

  color: #0a1019;

  cursor: pointer;
  display: flex;
  align-items: center;
`;

export const Telegram = styled(PiTelegramLogo)`
  color: #ffffff;
  width: 9px;
  height: 8px;
  margin-left: 5px;
`;

export const Shield = styled(IoShield)`
  color: #0a1019;
  width: 13px;
  height: 11px;
  margin-left: 5px;
`;

export const ChatConteiner = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 650px;
  overflow-y: auto;
  padding: 50px;
  border-radius: 10px;
  width: 900px;
  margin: 0 auto;

  @media (min-width: 1500px) {
    width: 675px;
  }
`;

export const SystemMessage = styled.div`
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 400;
  font-size: 8px;
  line-height: 10px;

  color: #b7b0c7;

  text-align: center;
  margin: 10px 0;
  width: 100%;
`;

export const AgentMessage = styled.div`
  background-color: #efe9ff;
  padding: 10px;
  border-radius: 10px;
  margin: 5px 0;
  max-width: 70%;
  align-self: flex-end;

  font-family: 'Geologica';
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  color: #0a1019;

  width: 340px;
`;

export const CustomerMessage = styled.div`
  background-color: #fff;
  padding: 10px;
  border-radius: 10px;
  margin: 5px 0;
  max-width: 70%;
  align-self: flex-start;

  font-family: 'Geologica';
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  color: #0a1019;
  border: 1px solid #f7f4fe;

  border: 1px solid #f7f4fe;
`;

export const ChatInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${({ side }) => (side === 'right' ? 'right' : 'left')};
  margin-top: 10px;

  position: relative;

  width: 100%;
`;

export const UserChatImg = styled.img`
  width: 30px;
  height: 30px;
  border-radius: 10px;
  right: ${({ side }) => (side === 'right' ? '-35px' : 'unset')};
  left: ${({ side }) => (side === 'right' ? 'unset' : '-35px')};

  position: absolute;
  bottom: -30px;
`;

export const UserChatName = styled.p`
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;

  color: #0a1019;

  padding: 0;
  margin: 0;
`;

export const TimeChat = styled.p`
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 400;
  font-size: 8px;
  line-height: 10px;
  margin-left: ${({ side }) => (side === 'right' ? '0' : '10px')};
  margin-right: ${({ side }) => (side === 'right' ? '10px' : '0')};
  margin-top: 0;
  margin-bottom: 0;

  color: #b7b0c7;
`;

export const MessageConteinerItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${({ side }) =>
    side === 'right' ? ' flex-end' : ' flex-start'};
`;
