import { createSlice } from '@reduxjs/toolkit';
import {
  getVipEmailsArchiveThunk,
  getSearchEmailThunk,
} from './VipEmailsArchiveAction';

const initialState = {
  data: null,
  searchData: null,
};

const vipemailsarchiveSlice = createSlice({
  name: 'vipemailsarchive',
  initialState,
  extraReducers: builder => {
    builder
      .addCase(getVipEmailsArchiveThunk.pending, state => {
        state.loading = true;
      })
      .addCase(getVipEmailsArchiveThunk.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
      })
      .addCase(getVipEmailsArchiveThunk.rejected, (state, action) => {
        state.error = action.error.message;
        state.loading = false;
      })

      .addCase(getSearchEmailThunk.pending, state => {
        state.loading = true;
      })
      .addCase(getSearchEmailThunk.fulfilled, (state, action) => {
        state.searchData = action.payload;
        state.loading = false;
      })
      .addCase(getSearchEmailThunk.rejected, (state, action) => {
        state.error = action.error.message;
        state.loading = false;
      });
  },
});
export default vipemailsarchiveSlice.reducer;
