import React, { useState } from 'react';
import Union from '../../../images/Union.png';
import {
  Dots,
  FirstItemConteiner,
  HeaderQaBlockItem,
  HeaderQaConteiner,
  HeaderQaMainItem,
  HeaderQaMainText,
  HeaderQaText,
  ImgConteiner,
  Item,
  MainQaImg,
  NavLinkStyled,
  SecondItemConteiner,
  TextQaConteiner,
  User,
} from './HeaderQa.styled';

const HeaderQa = ({ setChangePage, setChangeItem, changeItem }) => {
  const onClickButton = item => {
    setChangeItem(item);
  };

  const onChangePage = () => {
    setChangePage(true);
  };

  console.log(changeItem);

  return (
    <HeaderQaConteiner>
      <ImgConteiner>
        <MainQaImg src={Union} />
      </ImgConteiner>
      <TextQaConteiner>
        <HeaderQaText>Статистика</HeaderQaText>
      </TextQaConteiner>
      <HeaderQaMainItem>
        <HeaderQaMainText>Артём Ковальцев</HeaderQaMainText>
        <Dots />
      </HeaderQaMainItem>
      {/* <HeaderQaBlockItem>
        <FirstItemConteiner changeItem={changeItem}>
          <User onClick={() => onClickButton(true)} />
        </FirstItemConteiner>
        <SecondItemConteiner changeItem={changeItem}>
          <Item onClick={() => onClickButton(false)} />
        </SecondItemConteiner>
      </HeaderQaBlockItem> */}
    </HeaderQaConteiner>
  );
};

export default HeaderQa;
