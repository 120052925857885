import { useState, useEffect } from 'react';
import {
  Button,
  ButtonBlock,
  CloseButton,
  DateBlock,
  DateInput,
  SearchButton,
  SettingsContainer,
} from './DateSettings.styled';

const DateSettings = ({
  closeModal,
  setIsAfterDate,
  setIsBeforeDate,
  clickModal,
}) => {
  const [selectAfterDate, setSelectAfterDate] = useState(null);
  const [selectBeforeDate, setSelectBeforeDate] = useState(null);
  const [isDateSelectedViaButton, setIsDateSelectedViaButton] = useState(false);

  const handleAfterDateChange = event => {
    const selectedDate = event.target.value;
    setSelectAfterDate(selectedDate);
    setIsAfterDate(selectedDate);
    setIsDateSelectedViaButton(false);
  };

  const handleBeforeDateChange = event => {
    const selectedDate = event.target.value;
    setSelectBeforeDate(selectedDate);
    setIsBeforeDate(selectedDate);
    setIsDateSelectedViaButton(false);
  };

  const DateSearch = () => {
    if (selectAfterDate !== null || selectBeforeDate !== null) {
      setIsAfterDate(selectAfterDate);
      setIsBeforeDate(selectBeforeDate);
      closeModal();
    }
  };

  const handleFilter = filterType => {
    const currentDate = new Date();

    if (filterType === 'yesterday') {
      currentDate.setDate(currentDate.getDate() - 1);
    } else if (filterType === '7_days') {
      currentDate.setDate(currentDate.getDate() - 7);
    } else if (filterType === 'month') {
      currentDate.setMonth(currentDate.getMonth() - 1);
    }

    const formattedDate = currentDate.toISOString().split('T')[0];
    setIsAfterDate(formattedDate);
    setIsDateSelectedViaButton(true);
  };

  useEffect(() => {
    setIsAfterDate(false);
    setIsBeforeDate(false);
  }, [clickModal]);

  useEffect(() => {
    if (isDateSelectedViaButton) {
      closeModal();
    }
  }, [isDateSelectedViaButton, closeModal]);

  return (
    <SettingsContainer>
      <Button onClick={() => handleFilter('yesterday')}>Вчерашний день</Button>
      <Button onClick={() => handleFilter('7_days')}>7 дней</Button>
      <Button onClick={() => handleFilter('month')}>Месяц</Button>

      <DateBlock>
        <label>Выберите дату</label>
        <label>от:</label>
        <DateInput
          type="date"
          id="date"
          name="date"
          value={selectAfterDate || ''}
          onChange={handleAfterDateChange}
        />
        <label>до:</label>
        <DateInput
          type="date"
          id="date"
          name="date"
          value={selectBeforeDate || ''}
          onChange={handleBeforeDateChange}
        />
      </DateBlock>
      <ButtonBlock>
        <CloseButton onClick={closeModal}>Закрыть</CloseButton>
        <SearchButton onClick={DateSearch} title={'Поиск'} />
      </ButtonBlock>
    </SettingsContainer>
  );
};

export default DateSettings;
