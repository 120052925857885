import styled from 'styled-components';
import { RxAvatar } from 'react-icons/rx';

export const QaStatisticConteiner = styled.div`
  margin-left: 112px;
  border-right: 1px solid #dfdaeb;
  width: 269px;
  height: 100%;
  position: fixed;
`;

export const TodayStatisticConteiner = styled.div`
  margin-bottom: 15px;
`;

export const StatisticMainText = styled.h1`
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 28px;

  color: #0a1019;

  margin-bottom: 20px;
`;

export const TodayStatisticBlock = styled.div`
  display: flex;
`;

export const ItemStatisticBlock = styled.div`
  width: 122px;
  height: 75px;

  border: 1px solid #dfdaeb;
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-right: 5px;
  border-radius: 5px;
`;

export const StatisticText = styled.p`
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 500;
  font-size: 9px;
  line-height: 11px;

  color: #b7b0c7;
`;
export const StatisticSecondText = styled.p`
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 500;
  font-size: 8px;
  line-height: 9px;

  margin-bottom: 8px;
  margin-top: 10px;

  color: #b7b0c7;
`;

export const StatisticResult = styled.p`
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 38px;

  margin: 0;
  color: #1eaf69;
`;

export const StatisticSecondResult = styled.p`
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 38px;

  margin: 0;

  color: #ae88ff;
`;

export const RatioStatisticConteiner = styled.div`
  margin-bottom: 15px;
`;

export const RatioStatisticList = styled.ul`
  list-style: none;
  padding: 0;
`;

export const RatioStatisticItem = styled.li`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  display: flex;

  color: #0a1019;

  &:not(:last-child) {
    margin-bottom: 8px;
  }
`;

export const ColorBlock = styled.div`
  background: ${props => props.color};
  border-radius: 3px;
  width: 10px;
  height: 10px;
  margin-right: 10px;
`;

export const VerifiedStatisticConteiner = styled.div``;

export const VerifiedStatisticList = styled.ul`
  list-style: none;
  padding: 0;
  margin-left: 10px;
`;

export const VerifiedStatisticItem = styled.li`
  border: 1px solid transparent;
  border-radius: 5px;
  transition: border-color 0.3s ease;
  margin-bottom: 15px;
  cursor: pointer;
  width: 250px;

  &:hover {
    border-color: #8a8a8a;
  }
`;

export const Avatar = styled(RxAvatar)`
  width: 24px;
  height: 24px;

  margin-right: 8px;
  color: #8a8a8a;
`;

export const MainBlockInformatin = styled.div`
  display: flex;
  align-items: center;
  margin-left: 13px;
`;

export const TextBlockInformation = styled.div`
  display: flex;
  align-items: center;
  margin-left: 45px;
`;

export const AvatarImg = styled.img`
  margin-right: 8px;
  width: 24px;
  height: 24px;
`;

export const MainName = styled.p`
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;

  margin-bottom: 10px;
  color: #8a8a8a;
`;

export const TextInformation = styled.p`
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  margin-top: 0;

  color: #8a8a8a;

  margin-right: 14px;
`;

export const TimeInformation = styled.p`
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  margin-top: 0;

  color: #8a8a8a;
`;

export const RatioBar = styled.div`
  display: flex;
  height: 70px;
  width: 250px;
  border-radius: 6px;
  overflow: hidden;
  margin-bottom: 15px;
`;

export const ColorSection = styled.div`
  height: 100%;
  background-color: ${props => props.color};
  width: ${props => props.width};

  font-family: 'Geologica';
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
  display: flex;
  align-items: center;
  justify-content: center;

  color: #000000;
`;
