import { createAsyncThunk } from '@reduxjs/toolkit';
import { getVipEmailsArchive,getSearchEmail } from 'services/API';


export const getVipEmailsArchiveThunk = createAsyncThunk(
  'vipemailsarchive/getAll',
  async ({token, page}, thunkAPI) => {
    try {
      const response = await getVipEmailsArchive(token, page);
      const data = response.d;
      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const getSearchEmailThunk = createAsyncThunk(
    'vipemailsarchive/postAll',
    async ({token, email}, thunkAPI) => {
      try {
        const response = await getSearchEmail(token, email);
        return response;
      } catch (e) {
        return thunkAPI.rejectWithValue(e.message);
      }
    }
  );
  