import { createAsyncThunk } from '@reduxjs/toolkit';
import { getPaymentBotStat } from 'services/API';

export const getPaymentBotStatThunk = createAsyncThunk(
  'paymentBot/stat',
  async ({ token, dateFrom, dateTo }, thunkAPI) => {
    try {
      const response = await getPaymentBotStat(token, dateFrom, dateTo);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);
