import GeneralInfo from '../GeneralInfo/GeneralInfo';
import QaMessageArea from '../QaMessageArea/QaMessageArea';
import QaSideBar from '../QaSideBar/QaSideBar';
import QaStatistic from '../QaStatistic/QaStatistic';
import { MainQaConteiner, QaWorkPlaceConteiner } from './QaWorkPlace.styled';
import QaTicketDask from '../QaTicketDask/QaTicketDask';

const QaWorkPlace = ({ setChangePage }) => {

  return (
    <QaWorkPlaceConteiner>
      <MainQaConteiner>
        {/* <QaSideBar /> */}
        <QaStatistic />
        <QaMessageArea />
        <GeneralInfo />
      </MainQaConteiner>
    </QaWorkPlaceConteiner>
  );
};

export default QaWorkPlace;
