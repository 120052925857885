import styled from 'styled-components';
import { BsThreeDots } from 'react-icons/bs';
import { GoPersonFill } from 'react-icons/go';
import { LiaRoadSolid } from 'react-icons/lia';
import { NavLink } from 'react-router-dom';

export const HeaderQaConteiner = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #dfdaeb;
  position: fixed;
  width: 100%;
  height: 60px;
`;

export const ImgConteiner = styled.div`
  margin-right: 30px;

  width: 101px;

  display: flex;
  align-items: center;
  justify-content: center;
  height: 73px;
`;

export const MainQaImg = styled.img`
  width: 22px;
  height: 24px;
`;

export const HeaderQaText = styled.p`
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 28px;

  color: #0a1019;
`;

export const HeaderQaMainText = styled.p`
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 28px;

  color: #0a1019;

  @media (min-width: 1500px) {
    margin-right: 509px;
  }
  @media (min-width: 1600px) {
    margin-right: 597px;
  }
  @media (min-width: 1700px) {
    margin-right: 708px;
  }
  @media (min-width: 1800px) {
    margin-right: 808px;
  }
  @media (min-width: 1900px) {
    margin-right: 900px;
  }
`;

export const TextQaConteiner = styled.div`
  display: flex;
  align-items: center;
  border-right: 1px solid #dfdaeb;
  padding-right: 126px;
  margin-right: 28px;
`;

export const HeaderQaMainItem = styled.div`
  display: flex;
  align-items: center;
  border-right: 1px solid #dfdaeb;
  margin-right: 30px;
`;

export const HeaderQaBlockItem = styled.div`
  display: flex;
  align-items: center;
`;

export const FirstItemConteiner = styled.div`
  position: relative;
  margin-right: 30px;
  padding: 13px;

  // &:before {
  //   content: '';
  //   position: absolute;
  //   bottom: -4px;
  //   left: 0;
  //   width: 100%;
  //   height: ${props => (props.changeItem ? '4px' : '0')};
  //   background-color: #94e170;
  //   transition: height 0.3s ease;
  // }
`;

export const SecondItemConteiner = styled.div`
  position: relative;
  padding: 13px;

  // &:before {
  //   content: '';
  //   position: absolute;
  //   bottom: -4px;
  //   left: 0;
  //   width: 100%;
  //   height: ${props => (props.changeItem !== true ? '4px' : '0')};
  //   background-color: #94e170;
  //   transition: height 0.3s ease;
  // }
`;

export const HeaderQaButton = styled.button``;

export const Dots = styled(BsThreeDots)`
  cursor: pointer;
  width: 30px;
  height: 30px;
  margin-right: 28px;
`;

export const User = styled(GoPersonFill)`
  cursor: pointer;
  width: 25px;
  height: 25px;
  border: 3px solid #0a1019;
  border-radius: 8px;
  padding: 6px;
`;

export const Item = styled(LiaRoadSolid)`
  cursor: pointer;
  width: 25px;
  height: 25px;
  border: 3px solid #0a1019;
  border-radius: 8px;
  padding: 6px;
`;

export const NavLinkStyled = styled(NavLink)``;
