import {
  AgentMessage,
  ChatConteiner,
  ChatInfo,
  CustomerMessage,
  MessageConteiner,
  MessageConteinerItem,
  QaMessageAreaConteiner,
  SendButton,
  Shield,
  SystemMessage,
  TagBlock,
  TagButton,
  Telegram,
  TextAreaQa,
  TextAreaToolsConteiner,
  TimeChat,
  UserChatImg,
  UserChatName,
} from './QaMessageArea.styled';
import imgTest from '../../../images/userPhoto.png';

const QaMessageArea = () => {
  const agent = {
    messages: [
      {
        author: {
          type: 'system',
          name: 'System',
        },
        text: 'This is a system message.',
        time: '10:10:56',
      },
      {
        author: {
          type: 'agent',
          name: 'Agent Smith',
        },
        text: 'Hello! How can I assist you today?',
        time: '10:12:15',
      },
      {
        author: {
          type: 'customer',
          name: 'John Doe',
        },
        text: 'I need help with my order.',
        time: '10:13:24',
      },
      {
        author: {
          type: 'agent',
          name: 'Agent Smith',
        },
        text: 'Sure, can you please provide me with your order number?',
        time: '10:14:08',
      },
      {
        author: {
          type: 'customer',
          name: 'John Doe',
        },
        text: 'My order number is 12345.',
        time: '10:15:45',
      },
    ],
  };

  return (
    <QaMessageAreaConteiner>
      <ChatConteiner>
        {agent?.messages.map((item, index) => (
          <div key={index}>
            {item.author.type === 'system' && (
              <SystemMessage>{item.text} 10:10:56</SystemMessage>
            )}
            {item.author.type === 'agent' && (
              <>
                <ChatInfo side={'right'}>
                  <TimeChat side={'right'}>10:10:56</TimeChat>
                  <UserChatName>{item.author.name}</UserChatName>
                  <UserChatImg side={'right'} src={imgTest} />
                </ChatInfo>
                <MessageConteinerItem side={'right'}>
                  <AgentMessage>{item.text}</AgentMessage>
                </MessageConteinerItem>
              </>
            )}
            {item.author.type === 'customer' && (
              <>
                <ChatInfo side={'left'}>
                  <UserChatImg side={'left'} src={imgTest} />
                  <UserChatName>{item.author.name}</UserChatName>
                  <TimeChat side={'left'}>10:10:56</TimeChat>
                </ChatInfo>
                <MessageConteinerItem side={'left'}>
                  <CustomerMessage>{item.text}</CustomerMessage>
                </MessageConteinerItem>
              </>
            )}
          </div>
        ))}
      </ChatConteiner>
      <TextAreaQa />
      <TextAreaToolsConteiner>
        <TagBlock>
          <TagButton>
            <Shield />
            Add tag
          </TagButton>
        </TagBlock>
        <SendButton>
          Send
          <Telegram />
        </SendButton>
      </TextAreaToolsConteiner>
    </QaMessageAreaConteiner>
  );
};

export default QaMessageArea;
