import {
  BigNum,
  DateRangeDisplay,
  HeaderContainer,
  HeaderItem,
  MainInfo,
  MainInfoConatiner,
  MainLine,
  MainLineItem,
  MainLineText,
  MainPaymentBotStatConteiner,
  StatConteiner,
} from './PaymentBotStat.styled';
import {
  selectAccessToken,
  selectBotPaymentStat,
  selectSwitcher,
} from '../../redux/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { getPaymentBotStatThunk } from '../../redux/statistics/statisticsActions';

const PaymentBotStat = ({
  isAfterDate,
  isBeforeDate,
  clickModal,
  DeleteClick,
}) => {
  const dispatch = useDispatch();
  const stat = useSelector(selectBotPaymentStat);
  const token = useSelector(selectAccessToken);

  const calculateDates = () => {
    const today = new Date();

    const todayYear = today.getFullYear();
    const todayMonth = String(today.getMonth() + 1).padStart(2, '0');
    const todayDay = String(today.getDate()).padStart(2, '0');
    const todayFormatted = `${todayYear}-${todayMonth}-${todayDay}`;

    const yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);

    const yesterdayYear = yesterday.getFullYear();
    const yesterdayMonth = String(yesterday.getMonth() + 1).padStart(2, '0');
    const yesterdayDay = String(yesterday.getDate()).padStart(2, '0');
    const yesterdayFormatted = `${yesterdayYear}-${yesterdayMonth}-${yesterdayDay}`;

    return { yesterdayDate: yesterdayFormatted, todayDate: todayFormatted };
  };

  // Локальный стейт для хранения отправленных дат
  const [dateRange, setDateRange] = useState({ dateFrom: '', dateTo: '' });

  useEffect(() => {
    const { yesterdayDate, todayDate } = calculateDates();

    const dateFrom = isAfterDate || yesterdayDate;
    const dateTo = isBeforeDate || todayDate;

    // Обновляем локальный стейт с диапазоном дат
    setDateRange({ dateFrom, dateTo });

    console.log('Отправляем диапазон:', dateFrom, dateTo);

    dispatch(
      getPaymentBotStatThunk({
        token,
        dateFrom,
        dateTo,
      })
    );
  }, [isAfterDate, isBeforeDate]);

  const switcher = useSelector(selectSwitcher);

  return (
    <StatConteiner>
      <MainPaymentBotStatConteiner>
      <DateRangeDisplay>
          <h3>Filtered by dates:</h3>
          <p>
            {dateRange.dateFrom} - {dateRange.dateTo}
          </p>
        </DateRangeDisplay>

        <MainInfoConatiner switcher={switcher}>
          <HeaderContainer>
            <HeaderItem>General</HeaderItem>
            <HeaderItem>|</HeaderItem>
            <HeaderItem>Statistic</HeaderItem>
          </HeaderContainer>
          <MainInfo>
            <MainLine>
              <MainLineItem>
                <MainLineText
                  switcher={switcher}
                  title="Всего тикетов на Payment отдел"
                >
                  All Tickets
                </MainLineText>
              </MainLineItem>
              <MainLineItem>
                <BigNum switcher={switcher}>{stat?.count?.all_tickets}</BigNum>
              </MainLineItem>
            </MainLine>
            <MainLine>
              <MainLineItem>
                <MainLineText
                  switcher={switcher}
                  title="Необработанные тикеты нами (так как не обрабатываем данные платежные методы либо не нашли транзакцию в фундисте)"
                >
                  Invalid Tickets
                </MainLineText>
              </MainLineItem>
              <MainLineItem>
                <BigNum switcher={switcher}>
                  {stat?.count?.invalid_tickets}
                </BigNum>
              </MainLineItem>
            </MainLine>
            <MainLine>
              <MainLineItem>
                <MainLineText switcher={switcher} title="Количество пингов">
                  Pending Tickets
                </MainLineText>
              </MainLineItem>
              <MainLineItem>
                <BigNum switcher={switcher}>
                  {stat?.count?.pending_tickets}
                </BigNum>
              </MainLineItem>
            </MainLine>
            <MainLine>
              <MainLineItem>
                <MainLineText switcher={switcher} title="Обработали тикетов">
                  Processed Tickets
                </MainLineText>
              </MainLineItem>
              <MainLineItem>
                <BigNum switcher={switcher}>
                  {stat?.count?.processed_tickets}
                </BigNum>
              </MainLineItem>
            </MainLine>
            <MainLine>
              <MainLineItem>
                <MainLineText
                  switcher={switcher}
                  title="Необработанные тикеты (так как не нашли цифры на квитанции либо не смогли сверить реквизиты в платежной системе)"
                >
                  Unprocessed Tickets
                </MainLineText>
              </MainLineItem>
              <MainLineItem>
                <BigNum switcher={switcher}>
                  {stat?.count?.unprocessed_tickets}
                </BigNum>
              </MainLineItem>
            </MainLine>
            <MainLine>
              <MainLineItem>
                <MainLineText
                  switcher={switcher}
                  title="Тикеты, созданные ботом"
                >
                  Bot Tickets
                </MainLineText>
              </MainLineItem>
              <MainLineItem>
                <BigNum switcher={switcher}>{stat?.count?.bot_tickets}</BigNum>
              </MainLineItem>
            </MainLine>
            <MainLine>
              <MainLineItem>
                <MainLineText
                  switcher={switcher}
                  title="Наши пейменты создали тикетов с помощью телеграмм-бота"
                >
                  Operator Tickets
                </MainLineText>
              </MainLineItem>
              <MainLineItem>
                <BigNum switcher={switcher}>
                  {stat?.count?.operator_tickets}
                </BigNum>
              </MainLineItem>
            </MainLine>
            <MainLine>
              <MainLineItem>
                <MainLineText
                  switcher={switcher}
                  title="Не обработаны, так как не нашли цифры на квитанции"
                >
                  No Numbers Tickets
                </MainLineText>
              </MainLineItem>
              <MainLineItem>
                <BigNum switcher={switcher}>
                  {stat?.count?.no_numbers_tickets}
                </BigNum>
              </MainLineItem>
            </MainLine>
            <MainLine>
              <MainLineItem>
                <MainLineText
                  switcher={switcher}
                  title="Не обработаны, так как не нашли реквизитов в платежной системе, которые есть в квитанции "
                >
                  No Response Tickets
                </MainLineText>
              </MainLineItem>
              <MainLineItem>
                <BigNum switcher={switcher}>
                  {stat?.count?.no_response_tickets}
                </BigNum>
              </MainLineItem>
            </MainLine>
            <MainLine>
              <MainLineItem>
                <MainLineText
                  switcher={switcher}
                  title="Взято в работу из обработанных (%)"
                >
                  Solved Tickets
                </MainLineText>
              </MainLineItem>
              <MainLineItem>
                <BigNum switcher={switcher}>
                  {stat?.count?.solved_tickets}
                </BigNum>
              </MainLineItem>
            </MainLine>
            <MainLine>
              <MainLineItem>
                <MainLineText
                  switcher={switcher}
                  title="Обработали тикетов в %"
                >
                  All Processed Tickets
                </MainLineText>
              </MainLineItem>
              <MainLineItem>
                <BigNum switcher={switcher}>
                  {stat?.percents?.all_processed_tickets} %
                </BigNum>
              </MainLineItem>
            </MainLine>
            <MainLine>
              <MainLineItem>
                <MainLineText
                  switcher={switcher}
                  title="Взято в работу из общего числа %"
                >
                  Bot Processed Tickets
                </MainLineText>
              </MainLineItem>
              <MainLineItem>
                <BigNum switcher={switcher}>
                  {stat?.percents?.bot_processed_tickets} %
                </BigNum>
              </MainLineItem>
            </MainLine>
            <MainLine>
              <MainLineItem>
                <MainLineText
                  switcher={switcher}
                  title="Решено/Закрыто от общего числа %"
                >
                  Solved Tickets
                </MainLineText>
              </MainLineItem>
              <MainLineItem>
                <BigNum switcher={switcher}>
                  {stat?.percents?.solved_tickets} %
                </BigNum>
              </MainLineItem>
            </MainLine>
          </MainInfo>
        </MainInfoConatiner>
      </MainPaymentBotStatConteiner>
    </StatConteiner>
  );
};

export default PaymentBotStat;
