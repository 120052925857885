import styled from 'styled-components';

export const StatConteiner = styled.div`
  padding-left: 130px;
  padding-top: 100px;
`;

export const MainPaymentBotStatConteiner = styled.div`
  display: flex;
  align-items: left;
  flex-direction: column;
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 31px;
  background-color: #ae8cff;
  border-radius: 15px 15px 0 0;

  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 9px;
  line-height: 11px;
  text-align: center;

  color: #ffffff;
`;

export const HeaderItem = styled.div``;

export const MainInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 10px 13px 10px;
`;

export const MainInfoConatiner = styled.div`
  height: 585px;
  width: 345px;

  box-sizing: border-box;
  color: ${props => (props.switcher ? '#2E2E2E' : '#fff')};
  border: 1px solid #d3d3d3;
  border-radius: 15px;
`;

export const MainLine = styled.div`
  display: flex;
  align-items: center;

  box-sizing: border-box;
  border: 1px solid #d3d3d3;
  border-radius: 5px;
  height: 29px;
  width: 320px;
  margin-bottom: 12px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const MainLineItem = styled.div`
  display: flex;
  justify-content: center;
  width: 156px;
  border-right: 1px solid #dedede;
  height: 15px;

  &:last-child {
    border-right: 0;
  }
`;

export const StatisticTLConteiner = styled.div`
  padding-left: 159px;
  padding-top: 105px;
`;

export const MainLineText = styled.p`
  cursor: default;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  display: flex;
  align-items: center;
  text-align: center;
  color: ${props => (props.switcher ? '#fff' : '#0a1019')};

  margin: 0;
`;

export const BigNum = styled.p`
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  text-align: center;
  color: ${props => (props.switcher ? '#fff' : '#0a1019')};
  margin: 0;
`;

export const BigGreenNum = styled.p`
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #1eaf69;
  margin: 0;
`;

export const DateRangeDisplay = styled.div`
  text-align: left;
  margin-bottom: 20px;

  h3 {
    margin: 0;
    font-size: 18px;
    color: #333;
  }

  p {
    margin: 0;
    font-size: 16px;
    color: #666;
  }
`;
