import { createSlice } from '@reduxjs/toolkit';
import { getPaymentBotStatThunk } from './statisticsActions';

const initialState = {
  stat: null,
  loading: null,
  error: null,
};

const statisticsSlice = createSlice({
  name: 'statistics',
  initialState,
  extraReducers: builder => {
    builder
      .addCase(getPaymentBotStatThunk.pending, state => {
        state.loading = true;
      })
      .addCase(getPaymentBotStatThunk.fulfilled, (state, action) => {
        state.stat = action.payload;
        state.loading = false;
      })
      .addCase(getPaymentBotStatThunk.rejected, (state, action) => {
        state.error = action.error.message;
        state.loading = false;
      });
  },
});

export default statisticsSlice.reducer;
