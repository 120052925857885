import {
  Avatar,
  AvatarImg,
  ColorBlock,
  ColorSection,
  ItemStatisticBlock,
  MainBlockInformatin,
  MainName,
  QaStatisticConteiner,
  RatioBar,
  RatioStatisticConteiner,
  RatioStatisticItem,
  RatioStatisticList,
  StatisticMainText,
  StatisticResult,
  StatisticSecondResult,
  StatisticSecondText,
  StatisticText,
  TextBlockInformation,
  TextInformation,
  TimeInformation,
  TodayStatisticBlock,
  TodayStatisticConteiner,
  VerifiedStatisticConteiner,
  VerifiedStatisticItem,
  VerifiedStatisticList,
} from './QaStatistic.styled';

const QaStatistic = () => {
  return (
    <QaStatisticConteiner>
      <TodayStatisticConteiner>
        <StatisticMainText>Сегодня</StatisticMainText>
        <TodayStatisticBlock>
          <ItemStatisticBlock>
            <StatisticText>Кол-во проверенных</StatisticText>
            <StatisticResult>76</StatisticResult>
          </ItemStatisticBlock>
          <ItemStatisticBlock>
            <StatisticSecondText>Среднее время на проверку</StatisticSecondText>
            <StatisticSecondResult>12m</StatisticSecondResult>
          </ItemStatisticBlock>
        </TodayStatisticBlock>
      </TodayStatisticConteiner>
      <RatioStatisticConteiner>
        <StatisticMainText>Соотношение</StatisticMainText>
        <RatioBar>
          <ColorSection color="#DFDAEB" width={`${17}%`}>
            17%
          </ColorSection>
          <ColorSection color="#AE88FF" width={`${45}%`}>
            45%
          </ColorSection>
          <ColorSection color="#94E170" width={`${38}%`}>
            38%
          </ColorSection>
        </RatioBar>
        <RatioStatisticList>
          <RatioStatisticItem>
            <ColorBlock color={'#DFDAEB'} />
            Кол-во не проверенных
          </RatioStatisticItem>
          <RatioStatisticItem>
            <ColorBlock color={'#AE88FF'} />
            Кол-во отмеченых
          </RatioStatisticItem>
          <RatioStatisticItem>
            <ColorBlock color={'#94E170'} />
            Кол-во идеальных
          </RatioStatisticItem>
        </RatioStatisticList>
      </RatioStatisticConteiner>
      <VerifiedStatisticConteiner>
        <StatisticMainText>Проверено</StatisticMainText>
        <VerifiedStatisticList>
          <VerifiedStatisticItem>
            <MainBlockInformatin>
              {/* <AvatarImg /> */}
              <Avatar />
              <MainName>Геннадий Вичер</MainName>
            </MainBlockInformatin>
            <TextBlockInformation>
              <TextInformation>Chat already been rew</TextInformation>
              <TimeInformation>9m</TimeInformation>
            </TextBlockInformation>
          </VerifiedStatisticItem>
          <VerifiedStatisticItem>
            <MainBlockInformatin>
              {/* <AvatarImg /> */}
              <Avatar />
              <MainName>Геннадий Вичер</MainName>
            </MainBlockInformatin>
            <TextBlockInformation>
              <TextInformation>Chat already been rew</TextInformation>
              <TimeInformation>9m</TimeInformation>
            </TextBlockInformation>
          </VerifiedStatisticItem>
        </VerifiedStatisticList>
      </VerifiedStatisticConteiner>
    </QaStatisticConteiner>
  );
};

export default QaStatistic;
